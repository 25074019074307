import { useState, useEffect, useCallback, useRef } from "react";
import { useStoreContext } from "../../contexts/StoreContext";
import IStoreManager from "./interfaces/IStoreManager";
import IProductInfo from "./interfaces/IProductInfo";
import ICategoryInfo from "./interfaces/ICategoryInfo";
import ProductCard from "./components/ProductCard";
import Spinner from "react-bootstrap/Spinner";
import Accordion from "react-bootstrap/Accordion";
import { motion, AnimatePresence } from "framer-motion";
import { Auth } from "aws-amplify";
import axios from "axios";
import "./StoreManager.css";

export default function StoreManager(props: IStoreManager): JSX.Element {
  const storeContext = useStoreContext()!;
  const [productList, setProductList] = useState<IProductInfo[]>([]);
  const categoryList = useRef<ICategoryInfo[]>([]);
  const selectedCategory = useRef<ICategoryInfo>();
  const [categoriesExist, setCategoriesExist] = useState<Boolean>(true);
  const [productsExist, setProductsExist] = useState<Boolean>(true);
  const [productsLoading, setProductsLoading] = useState<Boolean>(false);
  const [accordionActiveItem, setAccordionActiveItem] = useState<string>("close");

  const httpClient = axios.create();

  httpClient.interceptors.request.use(async function (config: any) {
    const session = await Auth.currentSession();
    const idToken = await session.getIdToken();
    const token = await idToken.getJwtToken();
    config.headers.Authorization = token;
    return config;
  });

  useEffect(() => {
    async function getCategories() {
      try {
        const response = await httpClient.get(`/categories`);
        if (response.data.length > 0) {
          categoryList.current = response.data.sort(function (a: ICategoryInfo, b: ICategoryInfo) {
            if (a.categoryName.toLowerCase() < b.categoryName.toLowerCase()) return -1;
            if (a.categoryName.toLowerCase() > b.categoryName.toLowerCase()) return 1;
            return 0;
          });
          await onCategorySelected(categoryList.current[0]);
          setCategoriesExist(true);
        } else {
          setCategoriesExist(false);
        }
      } catch (error) {
        console.log(error);
      }
    }

    getCategories();
  }, [categoryList]);

  const onAddToCart = async (item: IProductInfo) => {
    try {
      storeContext.addItemToCart(item);
    } catch (error) {
      alert(error);
    }
  };

  const onCategorySelected = useCallback(
    async (menuItem: ICategoryInfo) => {
      setAccordionActiveItem("close");

      if (selectedCategory.current !== undefined) {
        if (selectedCategory.current.categoryId === menuItem.categoryId) {
          return;
        }
        selectedCategory.current.isSelected = false;
      }
      menuItem.isSelected = true;
      selectedCategory.current = menuItem;
      //setProductList([]);
      try {
        setProductsLoading(true);
        const response = await httpClient.get(`/products`, {
          params: {
            categoryId: menuItem.categoryId,
          },
        });
        if (response.data.length > 0) {
          //console.log(response.data);
          setProductsExist(true);
          setProductList(response.data);
        }
      } catch (error) {
        console.log(error);
        setProductsExist(false);
      } finally {
        setProductsLoading(false);
      }
    },
    [productList]
  );

  const onCategoryHeaderClickHandler = async () => {
    if (accordionActiveItem === "open") {
      setAccordionActiveItem("close");
    } else {
      setAccordionActiveItem("open");
    }
  };

  const eventAnimation = {
    animate: {
      opacity: 1,
    },
    initial: {
      opacity: 0.5,
    },
  };

  return (
    <div>
      {categoryList.current.length > 0 ? (
        <div className="store-container">
          <section className="store-left-menu">
            <Accordion className="store-left-menu-accordion" activeKey={accordionActiveItem}>
              <Accordion.Item className="store-left-menu-accordion-item" eventKey="open">
                <Accordion.Header className="store-left-menu-accordion-header" onClick={onCategoryHeaderClickHandler}>
                  <span className="store-left-menu-accordion-header-label">Product categories:</span>
                </Accordion.Header>
                <Accordion.Body className="store-left-menu-collapse">
                  {categoryList.current.map((item) => {
                    return (
                      <div
                        className={item.isSelected ? "store-left-menu-item-selected" : "store-left-menu-item"}
                        key={item.categoryId}
                        onClick={() => onCategorySelected(item)}
                      >
                        <label className="store-left-menu-item-label">{item.categoryName}</label>
                        <label className="store-left-menu-item-quantity">{item.categoryQuantity}</label>
                      </div>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="store-left-menu-main">
              <label className="store-menu-title-label">Product categories:</label>
              {categoryList.current.map((item) => {
                return (
                  <div
                    className={item.isSelected ? "store-left-menu-item-selected" : "store-left-menu-item"}
                    key={item.categoryId}
                    onClick={() => onCategorySelected(item)}
                  >
                    <label className="store-left-menu-item-label">{item.categoryName}</label>
                    <label className="store-left-menu-item-quantity">{item.categoryQuantity}</label>
                  </div>
                );
              })}
            </div>
          </section>
          {productList.length > 0 ? (
            <section className="store-products-section" style={{ opacity: productsLoading ? "50%" : "100%" }}>
              <AnimatePresence>
                {productList.map((item) => {
                  return (
                    <motion.div key={item.productId} variants={eventAnimation} initial="initial" animate="animate">
                      <ProductCard productInfo={item} onAddToCart={onAddToCart} key={item.productId} />
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </section>
          ) : !productsExist ? (
            <div className="store-products-section-empty">
              <span className="store-no-categories">
                You do not have any Products in your database. <br /> Please run generate data scripts.
              </span>
            </div>
          ) : (
            <section className="store-products-section-empty">
              <Spinner className="store-spinner" animation="border" role="status" variant="warning">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </section>
          )}
        </div>
      ) : !categoriesExist ? (
        <div className="store-container-empty">
          <span className="store-no-categories">
            You do not have any Categories in your database. <br /> Please run generate data scripts.
          </span>
        </div>
      ) : (
        <div className="store-container-empty">
          <Spinner className="store-spinner" animation="border" role="status" variant="warning">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
}
