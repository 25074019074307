import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import uniqolor from "uniqolor";
import { Auth } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "react-bootstrap/Spinner";
import { faTruckFast, faCartFlatbed, faCartShopping, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import "./OrderDetailsManager.css";
import IOrderDetailsInfo from "./interfaces/IOrderDetailsInfo";
import IOrderDetailsManager from "./interfaces/IOrderDetailsManager";

export default function OrderDetailsManager(props: IOrderDetailsManager): JSX.Element {
  const [orderList, setOrderList] = useState<IOrderDetailsInfo[]>([]);
  const [noData, setNoData] = useState<Boolean>(false);
  type OrderStatus = [string, IconDefinition];

  const urlParams = useParams();

  const httpClient = axios.create();

  httpClient.interceptors.request.use(async function (config: any) {
    const session = await Auth.currentSession();
    const idToken = await session.getIdToken();
    const token = await idToken.getJwtToken();
    config.headers.Authorization = token;
    return config;
  });

  function getOrderStatus(orderStatus: string): OrderStatus {
    switch (orderStatus) {
      case "CREATED":
        return ["Created", faCartShopping];
      case "PREPARED":
        return ["Prepared", faCartFlatbed];
      case "PROCESSED":
        return ["Processed", faTruckFast];
    }
    // console.log(orderStatus);
    return ["Not supported", faCartShopping];
  }

  useEffect(() => {
    async function getOrders() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const response = await httpClient.get(`/shipments`, {
          params: {
            userId: user.attributes.sub,
            orderId: urlParams.orderId,
          },
        });
        if (response.data.length > 0) {
          var orders = response.data.sort(function (a: IOrderDetailsInfo, b: IOrderDetailsInfo) {
            if (
              (a.shipmentProcessingDate ? a.shipmentProcessingDate : a.shipmentCreationDate) <
              (b.shipmentProcessingDate ? b.shipmentProcessingDate : b.shipmentCreationDate)
            )
              return -1;
            if (
              (a.shipmentProcessingDate ? a.shipmentProcessingDate : a.shipmentCreationDate) >
              (b.shipmentProcessingDate ? b.shipmentProcessingDate : b.shipmentCreationDate)
            )
              return 1;
            return 0;
          });

          setOrderList(orders);
          setNoData(false);
        } else {
          setNoData(true);
        }
        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    }

    getOrders();
  }, []);

  return (
    <div className="order-details-container">
      {orderList.length > 0 ? (
        <section className="order-details-section">
          <div className="order-details-link-container">
            <Link className="order-details-link" to="/orders">
              Return back to Orders
            </Link>
          </div>
          <span className="order-details-title-label">Your Order Details:</span>
          <Table striped bordered hover className="order-details-table">
            <thead>
              <tr>
                <th>Shipment Number</th>
                <th>Shipment Date</th>
                <th>Shipment Status</th>
                <th>Shipment Tracking</th>
              </tr>
            </thead>
            <tbody>
              {orderList.map((item) => {
                const status: OrderStatus = getOrderStatus(item.shipmentStatus);
                return (
                  <tr key={item.shipmentId}>
                    <td>{item.shipmentId.substring(0, 18)}</td>
                    <td>
                      {new Intl.DateTimeFormat("us-US", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(Date.parse(item.shipmentProcessingDate ? item.shipmentProcessingDate : item.shipmentCreationDate))}
                    </td>
                    <td className="order-details-table-cell-status-container">
                      <FontAwesomeIcon
                        className="order-details-table-cell-status-container-icon"
                        icon={status[1]}
                        color={uniqolor(item.shipmentId).color}
                      />
                      <span
                        className="order-details-table-cell-status-container-text"
                        style={{
                          color: uniqolor(item.shipmentId, {
                            saturation: 80,
                            lightness: 40,
                          }).color,
                        }}
                      >
                        {status[0]}
                      </span>
                    </td>
                    <td>{item.trackingNumber ? item.trackingNumber.substring(0, 18) : item.trackingNumber}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </section>
      ) : noData ? (
        <div className="order-details-container">
          <section className="order-details-section">
            <span className="orders-empty-orders">Order with Id {urlParams.orderId} is not available.</span>
            <Link className="order-details-empty-link" to="/orders">
              Return back to Orders
            </Link>
          </section>
        </div>
      ) : (
        <div className="orders-container-empty">
          <Spinner className="store-spinner" animation="border" role="status" variant="warning">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
}
