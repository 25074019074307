import { useStoreContext } from "../../contexts/StoreContext";
import INotificationManager from "./interfaces/INotificationManager";
import Table from "react-bootstrap/Table";
import "./NotificationManager.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faTruckFast, faCartFlatbed, faCartShopping, faTruckLoading } from "@fortawesome/free-solid-svg-icons";
import uniqolor from "uniqolor";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function CheckoutManager(props: INotificationManager): JSX.Element {
  const storeContext = useStoreContext()!;

  const getEventIcon = (eventType: string) => {
    switch (eventType) {
      case "OrderCreated":
        return faCartShopping;
      case "OrderProcessed":
        return faCartFlatbed;
      case "OrderFulfilled":
        return faTruckFast;
      case "ShipmentPrepared":
        return faCartFlatbed;
      case "ShipmentProcessed":
        return faTruckLoading;
      default:
        return faBell;
    }
  };

  return (
    <div className="notification-container">
      {storeContext.notificationItems.length > 0 ? (
        <section className="notification-section">
          <span className="notification-title-label">Your Notifications:</span>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Event</th>
                <th>Event Type</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {storeContext.notificationItems.map((item) => {
                return (
                  <tr
                    key={item.eventId}
                    style={{
                      backgroundColor: uniqolor(item.orderId, {
                        saturation: [35, 70],
                        lightness: [75, 90],
                        differencePoint: 50,
                      }).color,
                    }}
                  >
                    <td>
                      {new Intl.DateTimeFormat("us-US", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(item.eventDate)}
                    </td>
                    <td className="notification-table-column-center" title={"OrderId: " + item.orderId + "\nPlease click to see Order details"}>
                      <Link className="notification-link" to={"/orders/" + item.orderId} state={item.orderId}>
                        <FontAwesomeIcon
                          className="notification-link-icon"
                          icon={getEventIcon(item.eventType)}
                          color={uniqolor(item.orderId).color}
                        />
                      </Link>
                    </td>
                    <td>{item.eventType}</td>
                    <td className="notification-message-column">
                      <OverlayTrigger overlay={<Tooltip className="notification-tooltip">{item.message}</Tooltip>}>
                        <span>{item.message}</span>
                      </OverlayTrigger>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </section>
      ) : (
        <section className="notification-section">
          <span className="notification-empty-cart">You do not have any notifications.</span>
        </section>
      )}
    </div>
  );
}
