import axios from "axios";
import { Routes, Route } from "react-router-dom";
import StoreManager from "./portal/store/StoreManager";
import NavigationBar from "./portal/navigation/NavigationBar";
import ProfileManager from "./portal/profile/ProfileManager";
import CheckoutManager from "./portal/checkout/CheckoutManager";
import Amplify from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import NotificationManager from "./portal/notifications/NotificationManager";
import awsExports from "./aws-exports";
import OrderManager from "./portal/orders/OrderManager";
import OrderDetailsManager from "./portal/orders/OrderDetailsManager";
import AboutManager from "./portal/about/AboutManager";

declare const process: {
  env: {
    REACT_APP_PORTAL_API_URL: string;
    REACT_APP_PUB_SUB_REGION: string;
    REACT_APP_PUB_SUB_ENDPOINT: string;
    REACT_APP_PORTAL_TITLE: string;
  };
};

Amplify.configure(awsExports);

document.title = process.env.REACT_APP_PORTAL_TITLE;
axios.defaults.baseURL = process.env.REACT_APP_PORTAL_API_URL;

const formFields = {
  signUp: {
    given_name: {
      placeholder: "First Name",
      order: 1,
    },
    family_name: {
      placeholder: "Last Name",
      order: 2,
    },
    email: {
      order: 3,
    },
    password: {
      order: 4,
    },
    confirm_password: {
      order: 5,
    },
  },
};

function App(): JSX.Element {
  return (
    <div>
      <NavigationBar portalName={process.env.REACT_APP_PORTAL_TITLE} />
      <main id="main" className="u-full-vw u-full-vh">
        <Authenticator className="data-amplify-authenticator" formFields={formFields}>
          {() => (
            <Routes>
              <Route path="/" element={<StoreManager />} />
              <Route path="store" element={<StoreManager />} />
              <Route path="orders/:orderId" element={<OrderDetailsManager />} />
              <Route path="orders" element={<OrderManager />} />
              <Route path="about" element={<AboutManager />} />
              <Route path="cart" element={<CheckoutManager />} />
              <Route path="profile" element={<ProfileManager />} />
              <Route path="notifications" element={<NotificationManager />} />
            </Routes>
          )}
        </Authenticator>
      </main>
    </div>
  );
}

export default App;
