import { useState, useEffect } from "react";
import { useStoreContext } from "../../contexts/StoreContext";
import ICartProductInfo from "./interfaces/ICartProductInfo";
import ICheckoutManager from "./interfaces/ICheckoutManager";
import INewOrderInfo from "./interfaces/INewOrderInfo";
import INewOrderProductInfo from "./interfaces/INewOrderProductInfo";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import "./CheckoutManager.css";
import IProductInfo from "../store/interfaces/IProductInfo";
import CloseButton from "react-bootstrap/CloseButton";

export default function CheckoutManager(props: ICheckoutManager): JSX.Element {
  const storeContext = useStoreContext()!;
  const [cartItemsList, setCartItemsList] = useState<ICartProductInfo[]>([]);
  const [checkOutInProgress, setCheckOutInProgress] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  const httpClient = axios.create();

  httpClient.interceptors.request.use(async function (config: any) {
    const session = await Auth.currentSession();
    const idToken = await session.getIdToken();
    const token = await idToken.getJwtToken();
    config.headers.Authorization = token;
    return config;
  });

  const loadCart = async () => {
    var consolidatedProducts = new Array<ICartProductInfo>();
    const sortedProducts = storeContext.cartItems.sort(function (a: IProductInfo, b: IProductInfo) {
      if (a.productName.toLowerCase() < b.productName.toLowerCase()) return -1;
      if (a.productName.toLowerCase() > b.productName.toLowerCase()) return 1;
      return 0;
    });

    var totalPrice: number = 0;
    sortedProducts.forEach(function (product) {
      totalPrice = totalPrice + Number(product.productPrice);
      //console.log(product);
      const productExists = consolidatedProducts.find((item) => {
        return item.productId === product.productId;
      });
      if (productExists) {
        productExists.productQuantity = productExists.productQuantity + 1;
      } else {
        consolidatedProducts = [
          ...consolidatedProducts,
          {
            productId: product.productId,
            productName: product.productName,
            productPrice: product.productPrice,
            productQuantity: 1,
          },
        ];
      }
    });
    //console.log(consolidatedProducts);
    setCartItemsList(consolidatedProducts);
    setTotalPrice(totalPrice);
  };

  useEffect(() => {
    async function getCartContents() {
      try {
        await loadCart();
      } catch (error) {
        console.log(error);
      }
    }

    getCartContents();
  }, []);

  useEffect(() => {
    loadCart();
  }, [storeContext.cartItems]);

  const eventSwal = withReactContent(Swal);

  const onCheckOut = async () => {
    try {
      setCheckOutInProgress(true);
      const newOrder: INewOrderInfo = {
        userId: storeContext.userId,
        orderId: uuidv4(),
        products: new Array<INewOrderProductInfo>(),
      };

      cartItemsList.forEach(function (cartItem) {
        newOrder.products.push({
          productId: cartItem.productId,
          quantity: cartItem.productQuantity,
        });
      });

      const response = await httpClient.post(`/orders`, newOrder);
      console.log(response.data.body);

      storeContext.clearCart();
      setCartItemsList([]);
      setCheckOutInProgress(false);

      await eventSwal.fire({
        position: "center",
        icon: "success",
        title: "Order submitted!",
        showConfirmButton: false,
        timer: 1000,
      });
    } catch (error) {
      alert(error);
    } finally {
      setCheckOutInProgress(false);
    }
  };

  const onRemoveItem = async (productId: string) => {
    await storeContext.removeItemFromCart(productId);
  };

  return (
    <div className="checkout-container">
      {cartItemsList.length > 0 ? (
        <section className="checkout-section">
          <span className="checkout-cart-label">Your Cart:</span>
          <Table striped bordered hover className="checkout-table">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Product Price</th>
                <th>Product Quantity</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {cartItemsList.map((item) => {
                return (
                  <tr key={item.productId}>
                    <td>{item.productName}</td>
                    <td className="checkout-table-column-right">
                      {new Intl.NumberFormat("us-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(item.productPrice)}
                    </td>
                    <td className="checkout-table-column-right">{item.productQuantity}</td>
                    <td>
                      <CloseButton className="checkout-delete-item-button" onClick={() => onRemoveItem(item.productId)} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <span className="checkout-total-label">
            Total: <span> </span>
            {new Intl.NumberFormat("us-US", {
              style: "currency",
              currency: "USD",
            }).format(totalPrice)}
          </span>
          <div className="checkout-component">
            <button className="checkout-button" type="submit" disabled={checkOutInProgress} onClick={onCheckOut}>
              Check Out
              {checkOutInProgress ? (
                <Spinner className="checkout-spinner" as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                <span />
              )}
            </button>
          </div>
        </section>
      ) : (
        <section className="checkout-section">
          <span className="checkout-empty-cart">
            Your Cart is empty, it is time to{" "}
            <Link className="checkout-buy-link" to="/">
              buy
            </Link>{" "}
            something!
          </span>
        </section>
      )}
    </div>
  );
}
