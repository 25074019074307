import React, { useEffect, useState, useCallback } from "react";
import { StoreContext } from "./StoreContext";
import IProductInfo from "../portal/store/interfaces/IProductInfo";
import INotificationInfo from "../portal/notifications/interfaces/INotificationInfo";
import { PubSub, Auth, Hub } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ZenObservable } from "zen-observable-ts";

type Props = {
  children: React.ReactNode;
};

export const StoreContextProvider = ({ children }: Props) => {
  const [cartItems, setCartItems] = useState<IProductInfo[]>([]);
  const [notificationItems, setNotificationItems] = useState<INotificationInfo[]>([]);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [notification, setNotification] = useState<INotificationInfo>();
  const [webSocketConnected, setWebSocketConnected] = useState<Boolean>(false);
  const eventSwal = withReactContent(Swal);

  const addItemToCart = useCallback(
    async (item: IProductInfo) => {
      try {
        //console.log(item);
        setCartItems([...cartItems, item]);
      } catch (error) {
        console.error(error);
      }
    },
    [cartItems]
  );

  const removeItemFromCart = useCallback(
    async (productId: string) => {
      var items = cartItems.filter((item: IProductInfo) => item.productId !== productId);
      setCartItems(items);
    },
    [cartItems]
  );

  const addNotification = useCallback(
    async (item: INotificationInfo) => {
      try {
        //console.log(item);
        //setNotificationItems([...notificationItems, item]);
        setNotificationItems([item].concat(notificationItems));
      } catch (error) {
        alert(error);
      }
    },
    [notificationItems]
  );

  const clearCart = useCallback(async () => {
    try {
      setCartItems([]);
    } catch (error) {
      alert(error);
    }
  }, []);

  const clearNotifications = useCallback(async () => {
    try {
      setNotificationItems([]);
    } catch (error) {
      alert(error);
    }
  }, []);

  Hub.listen("auth", (data: { payload: { event: any } }) => {
    switch (data.payload.event) {
      case "signIn":
        applyUserName();
        //console.info("user signed in");
        break;
    }
  });

  const applyUserName = async () => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        Auth.currentUserInfo().then((info: { attributes: { [x: string]: string } }) => {
          setUserId(info.attributes["sub"]);
          setUserName(info.attributes["given_name"] + " " + info.attributes["family_name"]);
        });
      })
      .catch((err: any) => {
        //console.error(err);
      });
  };

  useEffect(() => {
    // console.info("Store Context Initialized");
    applyUserName();
    Hub.remove("auth", listener);
    Hub.listen("auth", listener);

    if (!webSocketConnected) {
      Auth.currentAuthenticatedUser()
        .then(() => {
          establishConnection(0);
        })
        .catch((err: any) => {
          //console.error(err);
        });
    }
    return () => Hub.remove("auth", listener);
  }, []);

  useEffect(() => {
    if (notification) {
      addNotification(notification);
    }
  }, [notification]);

  const onOrderEvent = useCallback(
    (data: string) => {
      console.info(data);
      const messageData = JSON.parse(JSON.stringify(data));

      var eventInfo: INotificationInfo = {
        eventId: messageData.value.eventId,
        orderId: messageData.value.orderId,
        eventDate: Date.parse(messageData.value.eventDate),
        eventType: messageData.value.eventType,
        message: messageData.value.message,
        shipmentId: messageData.value.shipmentId,
        shipments: [],
      };

      const niceMessage = getNiceMessage(eventInfo);

      eventInfo["message"] = niceMessage;

      setNotification(eventInfo);

      eventSwal.fire({
        position: "top-end",
        icon: "success",
        title: niceMessage,
        showConfirmButton: false,
        timer: 1000,
        toast: true,
        customClass: {
          container: "popup-custom-margin",
        },
      });
    },
    [notificationItems]
  );

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const onIotError = (error: string) => {
    console.error(error);
    const errorDetails = JSON.parse(JSON.stringify(error));
    if (errorDetails.error.errorCode === 8) {
      console.error(errorDetails.error.errorMessage);
    }
    PubSub.removePluggable("AWSIoTProvider");
    establishConnection(5000);
  };

  async function establishConnection(delay: number) {
    await timeout(delay);
    subscribeToIotTopic();
  }

  var webSocketSubscription: ZenObservable.Subscription;

  const subscribeToIotTopic = () => {
    Auth.currentUserInfo().then((info: { attributes: { [x: string]: any } }) => {
      const userId = info.attributes["sub"];
      console.info("Connecting to IoT Core for user " + userId);

      const iotProvider = new AWSIoTProvider({
        // clientId: userId,
        aws_pubsub_region: process.env.REACT_APP_PUB_SUB_REGION,
        aws_pubsub_endpoint: process.env.REACT_APP_PUB_SUB_ENDPOINT,
      });

      PubSub.addPluggable(iotProvider);

      if (webSocketSubscription) {
        webSocketSubscription.unsubscribe();
      }

      webSocketSubscription = PubSub.subscribe("order-status/" + userId).subscribe({
        next: (data: any) => onOrderEvent(data),
        error: (error: string) => onIotError(error),
        complete: () => console.log("Done"),
      });

      setWebSocketConnected(true);
      console.info("Connected to IoT Core for user " + userId);
      //console.log(webSocketSubscription.closed);
    });
  };

  const listener = (data: any) => {
    console.info(data.payload.event);
    switch (data.payload.event) {
      case "signIn":
        subscribeToIotTopic();
        break;
    }
  };

  const getNiceMessage = (eventDetails: INotificationInfo) => {
    switch (eventDetails.eventType) {
      case "OrderCreated":
        return "We received your order, it is being processed.";
      case "OrderProcessed":
        return "We processed your order and will ship it to you soon.";
      case "OrderFulfilled":
        return "Your order has been fulfilled, we processed all order shipments.";
      case "ShipmentPrepared":
        return "We packing a part of your order and will ship it to you soon.";
      case "ShipmentProcessed":
        return "We shipped a part of your order, you can track it using provided tracking number.";
      default:
        return "Generic Notification";
    }
  };

  return (
    <StoreContext.Provider
      value={{
        cartItems,
        notificationItems,
        addItemToCart,
        removeItemFromCart,
        clearCart,
        addNotification,
        clearNotifications,
        userId,
        userName,
        setUserName,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
